import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public index = 0;
  public layout = 'none';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title
  ) { }
  ngOnInit() {
  }
}
