import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [{
  path: ':id',
  loadChildren: () =>
    import('./qr-code-scanner/qr-code-scanner.module').then(
      (module) => module.QrCodeScannerModule
    ),
  data: { layout: 'none', title: 'Login', index: 0 },
},];

@NgModule({
  //   imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
